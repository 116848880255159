.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100vh;
  width: 100vw;

  color: #0F110C;
}

.TimerContainer {
  position: absolute;
  top: 0;
  left: 0;
  padding: 2.5rem;

  text-align: center;
  font-size: 2.5rem;
}

.TimerElapsed {
  text-align: right;
  font-size: 1.5rem;
  font-family: monospace, monospace;
  padding-top: 0.25rem;
}

.MovesContainer {
  position: absolute;
  top: 0;
  right: 0;
  padding: 2.5rem;

  text-align: center;
  font-size: 2.5rem;
}

.MoveCount {
  font-size: 1.5rem;
  font-family: monospace, monospace;
  padding-top: 0.25rem;
}

.ButtonRow {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 1em;
}

.ButtonRow button {
  background: none;
  border: none;

  color: white;
  background-color: #F58A07;
  font-weight: bold;
  font-size: 1.25rem;
  padding: 0.75em 1em;
  border-radius: 4px;
}

.ButtonRow button:hover {
  cursor: pointer;
  background-color: #DF7E07;
}

.CanvasContainer {
  border-radius: 12px;

  position: relative;
  overflow: hidden;
}

.ContactLink {
  margin-top: 1em;
  font-weight: bold;
  color: #90928B;
}

.Instructions {
  position: absolute;
  bottom: 0;
  left: 0;

  color: #90928B;
  padding: 2rem;
}
